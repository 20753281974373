import React, { useEffect, useRef, useState } from 'react'
import ClientsStyle from './../Clients'
import Button from '../../../components/Button'
import api from '../../../api'
import { get } from 'lodash'
import Layout from '../../../components/Layout'
import { ErrorModal } from '../../../components/Modal'
import CreateUserModal from '../../../components/Modal/CreateUserActive'
import UpdateUser from './UpdateUser'
import ClipLoader from 'react-spinners/ClipLoader'
import customMainApi from '../../../api/index'
import { Empty } from 'antd'
import _ from 'lodash'
import formatPhoneNumber from '../../../helpers/PhoneWithSpaces'

const ActiveClients = () => {
	const errorRef = useRef()
	const updateRef = useRef()
	const createUserRef = useRef()
	const succesCreateUserRef = useRef()

	const [isLoading, setIsLoading] = useState(false)
	const [clientName, setClientName] = useState('')
	const [clientPhone, setClientPhone] = useState('')
	const [clientCode, setClientCode] = useState('')
	const [page, setPage] = useState(0)
	const [userFields, setUserFields] = useState([])

	const [data, setData] = useState({
		data: [],
		currentPage: 0, //20
	})
	const [clientsData, setClientsData] = useState([])

	const [createUserLoading, setCreateUserLoading] = useState(false)
	const search = () => {
		setIsLoading(true)
		const formattedName = clientName.includes("'")
			? clientName.replace(/'/g, "''")
			: clientName
		let url =
			"BusinessPartners?$select=CardCode,CardName,Address,U_Telephone,U_Gender,U_Score,BPAddresses,Frozen,U_PS,U_workplace&$orderby=CardName asc&$filter=CardType eq 'cCustomer' and Frozen eq 'tNO'"
		if (
			clientCode.length >= 1 ||
			clientPhone.length >= 1 ||
			formattedName.length >= 1
		)
			if (formattedName.length >= 1)
				url += ` and contains(CardName, '${formattedName}') or contains(CardCode, '${formattedName}') `
		if (clientPhone.length >= 1)
			url += ` and contains(U_Telephone, '${clientPhone}')`
		url += `&$skip=${page * 10}`
		api
			.get(url, { headers: { Prefer: 'odata.maxpagesize=10' } })
			.then((res) => {
				const resData = JSON.parse(res.data).value
				setData({
					data: [...resData],
				})
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const handleBusinessPartner = _.debounce((searchFunc, value) => {
		searchFunc(value)
	}, 1000)

	useEffect(() => {
		search()
	}, [page])
	useEffect(() => {
		userFieldsFn()
	}, [])

	const viewItem = (v) => {
		updateRef.current?.open(v)
	}

	const newDatas = () => {
		if ((data.data.length = 10)) {
			setPage((prevState) => prevState + 1)
		} else {
			alert('boshqa malumot yoq')
		}
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OCRD'",
					$select: 'Name,ValidValuesMD,TableName',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	const oldData = () => {
		if (page === 0) {
			alert('boshqa malumot yoq')
		} else {
			setPage((prevState) => prevState - 1)
		}
	}
	const businessPartners = async (value) => {
		const formattedName = value.includes("'")
			? value.replace(/'/g, "''")
			: value
		api
			.get(`BusinessPartners`, {
				params: {
					$select: 'CardCode, CardName, U_Telephone,Frozen,CardType',
					$filter: `CardType eq 'cCustomer' and Frozen eq 'tNO' and contains(CardName, '${formattedName}') or contains(U_Telephone, '${formattedName}') or contains(CardCode, '${formattedName}')`,
				},
				headers: {
					Prefer: 'odata.maxpagesize=10',
				},
			})
			.then((res) => {
				setClientsData(JSON.parse(res.data).value || [])
			})
			.catch((err) => {
				if (err) errorRef.current?.open(get(err, 'message', ''))
				setIsLoading(false)
			})
	}
	const UsersInvoice = (v, e) => {
		e.stopPropagation()
		const url = `/users-invoices?CardCode=${v}`
		window.open(url, '_blank')
	}

	return (
		<Layout>
			<ClientsStyle>
				<div className="container">
					<p className="productTitle ">Активные клиенты</p>
					<div
						className="flex items-center justify-between
					"
					>
						{' '}
						<div className="grid gap-4 grid-cols-1 my-8 mb-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
							<div className="relative">
								<input
									className={'border-[1px] border-[#d9d9d9] p-2 rounded-md '}
									type="text"
									placeholder="Клиент"
									value={clientName}
									onChange={(e) => {
										setClientName(e.target.value)
										handleBusinessPartner(businessPartners, e.target.value)
									}}
								/>
								{clientName && (
									<div className="absolute top-10 left-0 bg-white w-full z-10 mt-1 border-[1px] border-[#d9d9d9] max-h-[300px] overflow-y-scroll">
										{clientsData.map((v, i) => {
											return (
												<div
													key={i}
													className="p-2 border-b cursor-pointer hover:bg-gray-100"
													onClick={() => {
														setClientCode(v.CardCode)
														setClientName(v.CardName)
														setClientsData([])
													}}
												>
													{v.CardName}
												</div>
											)
										})}
									</div>
								)}
							</div>
							<input
								type="text"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								placeholder="Телефон клиента"
								defaultValue={clientPhone}
								onChange={(v) => setClientPhone(v.target.value)}
							/>

							<Button onClick={() => search(0, 0)} isLoading={isLoading}>
								Поиск
							</Button>
						</div>
						<button
							className="bg-[#0A4D68] text-white px-4 py-2 mb-2 rounded-md"
							onClick={() => createUserRef.current?.open()}
						>
							+ Добавить
						</button>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-auto mb-8 mt-5 ">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className=" text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												Код клиента
											</th>
											<th scope="col" className="px-6 py-4">
												Имя Клиента
											</th>
											<th scope="col" className="px-6 py-4">
												Телефон
											</th>
											<th scope="col" className="px-6 py-4">
												Паспорт
											</th>
											<th scope="col" className="px-6 py-4">
												Статус
											</th>
											<th scope="col" className="px-6 py-4">
												Адрес
											</th>
											<th scope="col" className="px-6 py-4">
												История закупок
											</th>
										</tr>
									</thead>
									<tbody>
										{data.data.length > 0 ? (
											data.data.map((v, i) => (
												<tr
													className="bg-white border-b  hover:bg-gray-50 "
													key={i}
													onClick={() => viewItem(v)}
												>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{get(v, 'CardCode', '')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'CardName', '')}
													</td>
													<td className="px-6 py-4">
														{formatPhoneNumber(get(v, 'U_Telephone', ''))}
													</td>
													<td className="px-6 py-4">{get(v, 'U_PS', '')}</td>
													<td className="px-6 py-4">
														{get(v, 'U_Score', '') !== ''
															? userFields
																	?.find((item) => item.Name === 'Score')
																	?.ValidValuesMD.find(
																		(value) =>
																			get(v, 'U_Score', '') === value.Value,
																	)?.Description || ''
															: ''}
													</td>
													<td className="px-6 py-4">
														{get(v.BPAddresses[0], 'StreetNo', '') +
															' ' +
															get(v.BPAddresses[0], 'BuildingFloorRoom', '')}
													</td>
													<td className="px-6 py-4">
														<button
															className={
																'bg-[#0A4D68] w-[130px] h-[40px] text-white rounded-xl'
															}
															onClick={(e) => UsersInvoice(v.CardCode, e)}
														>
															История закупок
														</button>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description="Нет информации!"
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="topCard">
								<div className="flex items-center gap-2">
									<Button
										disabled={page === 0}
										className={'btn'}
										onClick={oldData}
									>
										{'<'}
									</Button>
									<p>{page + 1}</p>
									<Button
										disabled={data.data.length !== 10}
										className={'btn'}
										onClick={newDatas}
									>
										{'>'}
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
			</ClientsStyle>
			<ErrorModal getRef={(r) => (errorRef.current = r)} />
			<UpdateUser
				getRef={(ref) => (updateRef.current = ref)}
				onUpdated={() => search(data.currentPage, data.data)}
			/>
			<CreateUserModal
				isLoading={createUserLoading}
				getRef={(r) => (createUserRef.current = r)}
				onConfirm={() =>
					succesCreateUserRef.current?.open('Клиент успешно создан')
				}
			/>
		</Layout>
	)
}

export default ActiveClients
