import React, { useEffect } from 'react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import DrawerMenuStyle from './DrawerMenuStyle'
import { useLocation, useNavigate } from 'react-router-dom'
import { AiOutlineUnorderedList } from 'react-icons/ai'
import { get } from 'lodash'
import { useSelector } from 'react-redux'

const DrawerMenu = () => {
	const navigate = useNavigate()
	const location = useLocation()

	const [isOpen, setIsOpen] = React.useState(false)
	const [activeParent, setActiveParent] = React.useState()
	const [activeMenu, setActiveMenu] = React.useState(location.pathname.slice(1))
	const { getMe } = useSelector((state) => state.main)
	const data =
		get(getMe, 'Department2.Name', '') === 'DokonManager'
			? [
					{
						title: 'Главная страница',
						navigate: 'dashboard',
					},
					{
						title: 'Продажи',
						key: 'allProducts',
						children: [
							{
								title: 'Продажа',
								navigate: 'cash-payment',
							},
							{
								title: 'Рассрочка',
								navigate: 'installment-plan',
							},
						],
					},
					{
						title: 'Движение на складе',
						key: 'warehouseM',
						children: [
							{
								title: 'Покупки',
								navigate: 'purchases',
							},
							{
								title: 'Перемещение запасов',
								navigate: 'inventory-transfer',
							},

							{
								title: 'Склад остатка',
								navigate: 'reports',
							},
						],
					},

					{
						title: 'Клиенты',
						key: 'clients',
						children: [
							{
								title: 'Активные клиенты',
								navigate: 'active-clients',
							},
							{
								title: 'Неактивные клиенты',
								navigate: 'inactive-clients',
							},
						],
					},

					{
						title: 'Касса ',
						key: 'cash',
						children: [
							{
								title: 'Исходящий платеж',
								navigate: 'outgoing-payment',
							},
							{
								title: 'Отчет движения по кассе',
								navigate: 'cash-flow-report',
							},
							{
								title: 'Отчет ДДС',
								navigate: 'dds-report',
							},
							{
								title: 'Конвертация',
								navigate: 'refund',
							},
						],
					},
					{
						title: 'Возвраты',
						key: 'returns',
						children: [
							{
								title: 'Возвраты',
								navigate: 'purchase-returns',
							},
							{
								title: 'Скидки',
								navigate: 'forgivved',
							},
						],
					},

					{
						title: 'Выход',
						navigate: 'login',
					},
				]
			: get(getMe, 'Department2.Name', '') === 'Sotuv'
				? [
						{
							title: 'Сборы',
							navigate: 'recovery',
						},
						{
							title: 'Движение на складе',
							key: 'Transfers',
							children: [
								{
									title: 'Добавить продукт',
									navigate: 'addProduct',
								},
								{
									title: 'Перемещение запасов',
									navigate: 'inventory-transfer',
								},
								{
									title: 'Покупки',
									navigate: 'purchases',
								},
								{
									title: 'Склад остатка',
									navigate: 'reports',
								},
							],
						},
						{
							title: 'Продажи',
							key: 'allProducts',
							children: [
								{
									title: 'Продажа',
									navigate: 'cash-payment',
								},
								{
									title: 'Рассрочка',
									navigate: 'installment-plan',
								},
							],
						},
						{
							title: 'Покупки',
							navigate: 'purchases',
						},

						// {
						// 	title: 'Клиенты',
						// 	navigate: 'clients',
						// },
						{
							title: 'Выход',
							navigate: 'login',
						},
					]
				: get(getMe, 'Department2.Name', '') === 'Undiruvchi1'
					? [
							{
								title: 'Курс доллара',
								navigate: 'dollarCourse',
							},
							{
								title: 'Сборы',
								navigate: 'recovery',
							},
							{
								title: 'Отчет о сборе',
								navigate: 'recoveryChart',
							},
							{
								title: 'Клиент',
								key: 'clients',
								children: [
									{
										title: 'Активные клиенты',
										navigate: 'active-clients',
									},
									{
										title: 'Неактивные клиенты',
										navigate: 'inactive-clients',
									},
								],
							},
							{
								title: 'SMS',
								navigate: 'sms',
							},

							{
								title: 'Выход',
								navigate: 'login',
							},
						]
					: get(getMe, 'Department2.Name', '') === 'Undiruv'
						? [
								{
									title: 'Продажа',
									navigate: 'undiruv-installment-plan',
								},
								{
									title: 'Сборы',
									navigate: 'recovery',
								},

								// {
								// 	title: 'Клиенты',
								// 	navigate: 'clients',
								// },
								{
									title: 'Выход',
									navigate: 'login',
								},
							]
						: get(getMe, 'Department2.Name', '') === 'Scoring'
							? [
									{
										title: 'Клиенты',
										navigate: 'scoring-clients',
									},
									{
										title: 'Клиент',
										key: 'clients',
										children: [
											{
												title: 'Активные клиенты',
												navigate: 'active-clients',
											},
											{
												title: 'Неактивные клиенты',
												navigate: 'inactive-clients',
											},
										],
									},
									{
										title: 'Выход',
										navigate: 'login',
									},
								]
							: [
									{
										title: 'Главная страница',
										navigate: 'dashboard',
									},

									{
										title: 'Продажи',
										key: 'allProducts',
										children: [
											{
												title: 'Продажа',
												navigate: 'cash-payment',
											},
											{
												title: 'Рассрочка',
												navigate: 'installment-plan',
											},
										],
									},
									{
										title: 'Клиенты',
										key: 'clients',
										children: [
											{
												title: 'Активные клиенты',
												navigate: 'active-clients',
											},
											{
												title: 'Неактивные клиенты',
												navigate: 'inactive-clients',
											},
										],
									},
									{
										title: 'Коллекторы',
										key: 'reportRecovery',
										children: [
											{
												title: 'Сборы',
												navigate: 'recovery',
											},
											{
												title: 'Отчет о сборе',
												navigate: 'recoveryChart',
											},
											{
												title: 'SMS',
												navigate: 'sms',
											},
										],
									},

									{
										title: 'Движение на складе',
										key: 'Transfers',
										children: [
											{
												title: 'Покупки',
												navigate: 'purchases',
											},
											{
												title: 'Перемещение запасов',
												navigate: 'inventory-transfer',
											},
											{
												title: 'Склад остатка',
												navigate: 'reports',
											},
											{
												title: 'Добавить продукт',
												navigate: 'addProduct',
											},
										],
									},

									{
										title: 'Кредит калькулятор',
										navigate: 'settings',
									},
									{
										title: 'Касса ',
										key: 'cash',
										children: [
											{
												title: 'Исходящий платеж',
												navigate: 'outgoing-payment',
											},
											{
												title: 'Отчет движения по кассе',
												navigate: 'cash-flow-report',
											},
											{
												title: 'Отчет ДДС',
												navigate: 'dds-report',
											},
											{
												title: 'Курс доллара',
												navigate: 'dollarCourse',
											},
											{
												title: 'Конвертация',
												navigate: 'refund',
											},
										],
									},

									{
										title: 'Возвраты',
										key: 'returns',
										children: [
											{
												title: 'Возвраты',
												navigate: 'purchase-returns',
											},
											{
												title: 'Скидка',
												navigate: 'forgivved',
											},
										],
									},

									{
										title: 'Выход',
										navigate: 'login',
									},
								]

	const handleActive = (n) => {
		data.map((v) => {
			if (v.key === n) {
				setActiveParent(n)
				v.children.map((child) => {
					if (child.navigate === n) {
						setActiveMenu(n)
					}
				})
			} else if (v.navigate === n) {
				setActiveMenu(n)
			}
		})
	}

	const toggleDrawer = () => {
		setIsOpen((prevState) => !prevState)
	}

	const goToScreen = (n) => {
		if (n === 'login') {
			navigate(`/${n}`, { replace: true })
		} else {
			navigate(`/${n}`)
		}
	}

	function handleParent(key) {
		if (key === activeParent) {
			setActiveParent(null)
		} else {
			setActiveParent(key)
		}
	}

	useEffect(() => {
		data.map((v) => {
			if (v.children) {
				v.children.map((child) => {
					if (child.navigate === activeMenu) {
						setActiveParent(v.key)
					}
				})
			}
		})
	}, [])

	return (
		<DrawerMenuStyle>
			<button onClick={toggleDrawer} className="listBtn">
				<AiOutlineUnorderedList />
			</button>
			<Drawer open={isOpen} onClose={toggleDrawer} direction="left">
				<div className="drawer">
					<p className="menuTitle ">Меню</p>

					{data.map((v, i) => {
						if (v.children)
							return (
								<div key={i}>
									<button
										className={
											v.key === activeParent ? 'btnParentActive' : 'btn'
										}
										onClick={() => handleParent(v.key)}
									>
										{v.title} {activeParent !== v.key ? '>' : '<'}
									</button>
									{activeParent === v.key &&
										v.children.map((child, i) => (
											<button
												key={i}
												className={
													activeMenu === child.navigate ? 'btnActive' : 'btn'
												}
												onClick={() => {
													goToScreen(child.navigate)
													handleActive(child.navigate)
													handleActive(v.key)
												}}
											>
												{child.title}
											</button>
										))}
								</div>
							)
						else
							return (
								<button
									key={i}
									className={v.navigate === activeMenu ? 'btnActive' : 'btn'}
									onClick={() => {
										goToScreen(v.navigate)
										handleActive(v.navigate)
									}}
								>
									{v.title}
								</button>
							)
					})}
				</div>
			</Drawer>
		</DrawerMenuStyle>
	)
}

export default DrawerMenu
