import React, { useEffect, useRef, useState } from 'react'
import ClientsStyle from './Clients'
import Button from '../../components/Button'
import api, { customFuncsApi } from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal, SuccessModal } from '../../components/Modal'
import UpdateUser from './UpdateUser'
import ClipLoader from 'react-spinners/ClipLoader'
import ScoreCreateUser from '../../components/Modal/ScoreUserCreate'
import { Empty } from 'antd'
import formatPhoneNumber from '../../helpers/PhoneWithSpaces'

const ScoringClients = () => {
	const errorRef = useRef()
	const updateRef = useRef()
	const createUserRef = useRef()
	const succesCreateUserRef = useRef()

	const [sortConfig, setSortConfig] = useState({
		column: null,
		direction: null,
	})

	const [isLoading, setIsLoading] = useState(false)
	const [clientName, setClientName] = useState('')
	const [clientPhone, setClientPhone] = useState('')
	const [clientStreet, setClientStreet] = useState('')
	const [userFields, setUserFields] = useState([])

	const [clientCode, setClientCode] = useState('')
	const [maxPage, setMaxPage] = useState(10)

	const [page, setPage] = useState(0)
	const [currentPage, setCurrentPage] = useState(0)

	const [data, setData] = useState({
		data: [],
	})

	const search = (sortConfig) => {
		setIsLoading(true)
		console.log(sortConfig)
		const formattedName = clientName.includes("'")
			? clientName.replace(/'/g, "''")
			: clientName

		let url = `xsSql/getClientsForScoring?${sortConfig.direction !== null ? `orderBy=[{"name": "${sortConfig.column}", "type" : "${sortConfig.direction}"}]&` : ``}$skip=${page * 10}`
		// if (
		// 	setMaxPage.length >= 1 ||
		// 	clientPhone.length >= 1 ||
		// 	clientName.length >= 1
		// )
		// 	url += "&$filter=CardType eq 'C'"
		if (formattedName.length >= 1) url += `&CardName='${formattedName}'`
		if (clientCode.length >= 1) url += `&CardCode='${clientCode}'`
		if (clientPhone.length >= 1) url += `&U_Telephone='${clientPhone}'`
		if (clientStreet.length >= 1) url += `&StreetNo='${clientStreet}'`
		console.log(sortConfig)

		// url += `?$skip=${page * 10}`
		customFuncsApi
			.get(url, { headers: { Prefer: `odata.maxpagesize=${maxPage}` } })
			.then((res) => {
				const resData = res.data.value
				setData({
					data: [...resData],
				})
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	useEffect(() => {
		search(sortConfig)
		userFieldsFn()
	}, [page, maxPage, sortConfig])

	const viewItem = (v) => {
		updateRef.current?.open(v)
	}

	const handleCreateUser = () => {
		createUserRef.current?.open()
	}

	const userFieldsFn = () => {
		api
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OINV'",
					$select: 'Name,ValidValuesMD,TableName',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	const handleSort = (column) => {
		let direction = 'ASC'
		if (sortConfig.column === column && sortConfig.direction === 'ASC') {
			direction = 'DESC'
		} else if (
			sortConfig.column === column &&
			sortConfig.direction === 'DESC'
		) {
			direction = null
		}
		setSortConfig({ column, direction })
	}

	const UsersInvoice = (v, e) => {
		e.stopPropagation()
		const url = `/users-invoices?CardCode=${v}`
		window.open(url, '_blank')
	}

	const newDatas = () => {
		if ((data.data.length = maxPage)) {
			setPage((prevState) => prevState + Number(String(maxPage)[0]))
			setCurrentPage((prevState) => prevState + 1)
		} else {
			alert('boshqa malumot yoq')
		}
	}

	const oldData = () => {
		if (page === 0) {
			alert('boshqa malumot yoq')
		} else {
			setPage((prevState) => prevState - Number(String(maxPage)[0]))
			setCurrentPage((prevState) => prevState - 1)
		}
	}

	return (
		<Layout>
			<ClientsStyle>
				<div className="container">
					<p className="productTitle ">Клиенты</p>
					<div className="flex items-center justify-between mt-8 mb-8">
						<div className="grid gap-4 grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
							<input
								type="text"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								placeholder="Имя Клиента"
								defaultValue={clientName}
								onChange={(v) => setClientName(v.target.value)}
							/>
							<input
								type="text"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								placeholder="Телефон клиента"
								defaultValue={clientPhone}
								onChange={(v) => setClientPhone(v.target.value)}
							/>

							<input
								type="text"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								placeholder="Улица клиента"
								defaultValue={clientStreet}
								onChange={(v) => setClientStreet(v.target.value)}
							/>

							<Button onClick={() => search(sortConfig)} isLoading={isLoading}>
								Поиск
							</Button>
						</div>
						<Button className="w-[170px]" onClick={handleCreateUser}>
							+ Добавить
						</Button>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className=" overflow-auto mb-8">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50">
										<tr>
											<th
												scope="col"
												className="px-6 py-4 cursor-pointer"
												onClick={() => handleSort('CardCode')}
											>
												Код клиента{' '}
												{sortConfig.column === 'CardCode'
													? sortConfig.direction === 'ASC'
														? '↑'
														: sortConfig.direction === 'DESC'
															? '↓'
															: ''
													: ''}
											</th>
											<th
												scope="col"
												className="px-6 py-4 cursor-pointer"
												onClick={() => handleSort('CardName')}
											>
												Имя Клиента{' '}
												{sortConfig.column === 'CardName'
													? sortConfig.direction === 'ASC'
														? '↑'
														: sortConfig.direction === 'DESC'
															? '↓'
															: ''
													: ''}
											</th>
											<th
												scope="col"
												className="px-6 py-4 cursor-pointer"
												onClick={() => handleSort('U_Telephone')}
											>
												Телефон{' '}
												{sortConfig.column === 'U_Telephone'
													? sortConfig.direction === 'ASC'
														? '↑'
														: sortConfig.direction === 'DESC'
															? '↓'
															: ''
													: ''}
											</th>
											<th
												scope="col"
												className="px-6 py-4 cursor-pointer"
												onClick={() => handleSort('U_PS')}
											>
												Паспорт{' '}
												{sortConfig.column === 'U_PS'
													? sortConfig.direction === 'ASC'
														? '↑'
														: sortConfig.direction === 'DESC'
															? '↓'
															: ''
													: ''}
											</th>
											<th
												scope="col"
												className="px-6 py-4 cursor-pointer"
												// onClick={() => handleSort('U_Score')}
											>
												Статус{' '}
												{sortConfig.column === 'U_Score'
													? sortConfig.direction === 'ASC'
														? '↑'
														: sortConfig.direction === 'DESC'
															? '↓'
															: ''
													: ''}
											</th>
											<th
												scope="col"
												className="px-6 py-4 cursor-pointer"
												// onClick={() =>
												// 	handleSort('LastInvoice.LastComment.U_Text')
												// }
											>
												Последная коментария{' '}
												{sortConfig.column === 'LastInvoice.LastComment.U_Text'
													? sortConfig.direction === 'ASC'
														? '↑'
														: sortConfig.direction === 'DESC'
															? '↓'
															: ''
													: ''}
											</th>
											<th
												scope="col"
												className="px-6 py-4 cursor-pointer"
												// onClick={() => handleSort('U_MIB')}
											>
												Регион{' '}
												{sortConfig.column === 'U_MIB'
													? sortConfig.direction === 'ASC'
														? '↑'
														: sortConfig.direction === 'DESC'
															? '↓'
															: ''
													: ''}
											</th>
											<th
												scope="col"
												className="px-6 py-4 cursor-pointer"
												// onClick={() => handleSort('Address')}
											>
												Улица{' '}
												{sortConfig.column === 'Address'
													? sortConfig.direction === 'ASC'
														? '↑'
														: sortConfig.direction === 'DESC'
															? '↓'
															: ''
													: ''}
											</th>
											<th scope="col" className="px-6 py-4">
												История закупок
											</th>
										</tr>
									</thead>
									<tbody>
										{data.data.length > 0 ? (
											data.data.map((v, i) => {
												return (
													<tr
														className="bg-white border-b hover:bg-gray-50"
														key={i}
														onClick={() => viewItem(v)}
													>
														<td className="px-6 py-4 font-medium text-gray-900">
															{get(v, 'CardCode', '')}
														</td>
														<td className="px-6 py-4">
															{get(v, 'CardName', '')}
														</td>
														<td className="px-6 py-4">
															{formatPhoneNumber(get(v, 'U_Telephone', ''))}
														</td>
														<td className="px-6 py-4">{get(v, 'U_PS', '')}</td>

														<td className="px-6 py-4">
															{get(v, 'LastInvoice.U_Score', '') !== ''
																? userFields
																		?.find((item) => item.Name === 'Score')
																		?.ValidValuesMD.find(
																			(value) =>
																				get(v, 'LastInvoice.U_Score', '') ===
																				value.Value,
																		)?.Description || ''
																: ''}
														</td>
														<td className="px-6 py-4">
															{get(v, 'LastInvoice.LastComment.U_Text', '')}
														</td>
														<td className="px-6 py-4">
															{get(v, 'ADDR_DISTRICTS.Name', '')}
														</td>
														<td className="px-6 py-4">
															{(get(v, 'StreetNo', '') || '') +
																'    ' +
																(get(v, 'Building', '') || '')}
														</td>
														<td className="px-6 py-4">
															<button
																className={
																	'bg-[#0A4D68] w-[130px] h-[40px] text-white rounded-xl'
																}
																onClick={(e) => UsersInvoice(v.CardCode, e)}
															>
																История закупок
															</button>
														</td>
													</tr>
												)
											})
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description="Нет информации!"
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="topCard">
								<select
									value={maxPage}
									onChange={(e) => {
										setMaxPage(e.target.value)
										setPage(0)
										setCurrentPage(0)
									}}
								>
									<option value="10">10</option>
									<option value="20">20</option>
									<option value="30">30</option>
									<option value="50">50</option>
									<option value="80">80</option>
								</select>
								<div className="flex items-center gap-2 ">
									<Button
										disabled={page === 0}
										className={'btn'}
										onClick={oldData}
									>
										{'<'}
									</Button>
									<p>{currentPage + 1}</p>
									<Button
										// disabled={data.data.length !== maxPage}
										className={'btn'}
										onClick={newDatas}
									>
										{'>'}
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
			</ClientsStyle>
			<ErrorModal getRef={(r) => (errorRef.current = r)} />
			<UpdateUser
				getRef={(ref) => (updateRef.current = ref)}
				onUpdated={() => search(sortConfig)}
			/>
			<ScoreCreateUser
				getRef={(ref) => (createUserRef.current = ref)}
				onConfirm={() =>
					succesCreateUserRef.current?.open('Клиент успешно создан')
				}
				onClose={() => {
					succesCreateUserRef.current?.close()
				}}
				onUpdated={() => search(sortConfig)}
			/>
			<SuccessModal
				getRef={(r) => {
					succesCreateUserRef.current = r
				}}
			/>
		</Layout>
	)
}

export default ScoringClients
